import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FiArrowRight, FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
// image import
import {
  bg1, bg2, bg3,
  client01, client02, client03, client04, client05, client06, client07, client08,
  bg01,
  about,
  ico01,ico02,ico03,ico04,ico05,ico06,ico07,tk1,tk2
} from "../../components/imageImport";
import '../../common.css'

const AboutUs = () => {
  const navigate = useNavigate()
  const clientRecord = [
    {
      image: client01,
      name: 'Sir Gavin',
      position: 'Founder',
    },
    {
      image: client02,
      name: 'Brad Burton',
      position: 'Founder Networking',
    },    
    {
      image: client03,
      name: 'Meddy Sahebi',
      position: 'Founder Tokenizz',
    },
    {
      image: client04,
      name: 'David Weir',
      position: 'Paralympic Medalist CBE',
    },
    {
      image: client05,
      name: 'Connortierney',
      position: 'Former BKB World Champion',
    },
    {
      image: client06,
      name: 'Mark Redknapp',
      position: 'Founder',
    },
    {
      image: client07,
      name: 'Andrew Mcadams',
      position: 'Founder',
    },
    {
      image: client08,
      name: 'Roy Shanks',
      position: 'Founder',
    },
  ]

  const blogRecord = [
    {
      image: bg1,
      title: 'Mindfulness Activities for Kids & Toddlers with NFT',
      createdBy: '@callyjoe',
      type: 'Arts',
    },
    {
      image: bg2,
      title: 'Save Thousands Of Lives Through This NFT',
      createdBy: '@kristyhoney',
      type: 'Illustration',
    },
    {
      image: bg3,
      title: 'A place where technology meets craftsmanship',
      createdBy: '@pandaone',
      type: 'Music',
    },
  ]

  return (
    <>
      <Navbar />
      {/* Start Home */}
      <section
        className="bg-half-100 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Meet Tokensport
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  NFT marketplace where anything is possible and all are welcome
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}


      <section className="section text-extra-dark-gray bg-section-white">
            <div class="container">               
                <div class="row row-cols-1 row-cols-lg-3 row-cols-sm-2 justify-content-center">                 
                    <div class="col-12 col-md-6 md-margin-30px-bottom xs-margin-15px-bottom last-paragraph-no-margin" >
                    <img src={tk1} alt="" className='img-fluid'/>
                    </div>
                   
                    <div class="col-12 col-md-6 md-margin-30px-bottom xs-margin-15px-bottom last-paragraph-no-margin">
                       <h2 class="heading fw-semibold sub-heading text-white">Utility tokens for teams and players!!!</h2>
                       <p className="p-txt">Tokensport Utility Token Communities Utility token for teams and players that can be exchanged for earn interest toward valuable Sponsor items like free food or services or collected to “trade up” for some security tokens. STO team offerings we retain a fixed % of security tokens to allow holders of utility tokens to transfer into. Promotions, loyalty programs, Events, sponsors and fans can buy utility tokens and collect them or exchange for valuable Sponsor items like free food or services.</p>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-lg-3 row-cols-sm-2 justify-content-center">                
                                       
                    <div class="col-12 col-md-6 md-margin-30px-bottom xs-margin-15px-bottom last-paragraph-no-margin">
                       <h2 class="heading fw-semibold sub-heading text-white">Gaming</h2>
                       <p className="p-txt">Tokensport offical gaming partner Playbetr: “Bet today and get a chance to own part of the team” We let everyone use our utility tokens to bet for fun and Charity and they can be exchanged for valuable sponsor goods and services or with enough tokens collected converted to security tokens in teams we do STO offerings for (team STO offerings reserve 10% of the team STO tokens for this arbitrage) Example: X number of utility tokens gets you a security token and as the value of security tokens increase so does number of utility tokens needed to exchange
</p>
                    </div>

                    <div class="col-12 col-md-6 md-margin-30px-bottom xs-margin-15px-bottom last-paragraph-no-margin" >
                    <img src={tk2} alt="" className='img-fluid'/>
                    </div>
                </div>
            </div>            
        </section>


      <section id="about_players" class="section pb-0 ds">   
				<div class="container">
        <div class="row justify-content-center align-items-center pb-5 text-center">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="heading-title pb-4">
                    <span>What Makes Us Different</span>
                    <h1>Players / Athletes</h1>
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-10 text-center">
                <p class="text-center text-md-center">Create an account to manage / fund / publish mediate and create revenue / sponsorship / investment in a sports only dedicated platform marketplace
</p>
            </div>
        </div>
					<div class="row">
						<div class="col-xs-12 col-md-12 col-lg-4 fw-column">
							<div class="divider-lg-30"></div>
							<div class="icon-box icon_left mobile-center">
								<div class="box_icon color-main">
									<img src={ico01} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
										<a href="#">Funding</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
							<div class="icon-box icon_left mobile-center">
								<div class="box_icon color-main">
									<img src={ico03} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">New Audiences</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
							<div class="icon-box icon_left mobile-center">
								<div class="box_icon color-main">
									<img src={ico02} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">Smart Contracts</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
						</div>

						<div class="col-xs-12 col-md-12 col-lg-4 text-center fw-column align-self-end">
							<div class="divider-50 d-lg-none"></div>
							<div class="img-wrap avatar-player mt--30">
								<img src={ico07} alt=""/>
							</div>
							<div class="divider-50 d-lg-none"></div>
						</div>

						<div class="col-xs-12 col-md-12 col-lg-4 fw-column">
							<div class="divider-lg-30"></div>
							<div class="icon-box icon_right mobile-center">
								<div class="box_icon color-main">
									<img src={ico04} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">Sponsorship</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
							<div class="icon-box icon_right   mobile-center">
								<div class="box_icon color-main">
									<img src={ico05} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">Virtual Training</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
							<div class="icon-box icon_right   mobile-center">
								<div class="box_icon color-main">
									<img src={ico06} alt=""/>
								</div>
								<div class="box-wrap">
									<h5 class="box-heading">
                  <a href="#">NFTS</a>
									</h5>
									<div class="box-content">
                  Buy once, and it is yours forever. Perfect deal!
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

        <div class="header">
<div>
<svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="waves"
      preserveAspectRatio="none"
      viewBox="0 24 150 28"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z"
        ></path>
      </defs>
      <g className="parallax">
        <use x="48" fill="rgba(255, 255, 255, 0.7)" xlinkHref="#gentle-wave"></use>
        <use
          x="48"
          y="3"
          fill="rgba(255, 255, 255, 0.5)"
          xlinkHref="#gentle-wave"
        ></use>
        <use
          x="48"
          y="5"
          fill="rgba(255, 255, 255, 0.3)"
          xlinkHref="#gentle-wave"
        ></use>
        <use x="48" y="7" fill="#000" xlinkHref="#gentle-wave"></use>
      </g>
    </svg>
</div>
</div>

			</section>

      {/* Start Section */}
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="about-image position-relative">
                <img src={about} className="img-fluid rounded" alt="" />
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h6 className="text-primary fw-normal">
                What Makes Us Different
                </h6>
                <h4 className="title mb-4">
                Download Our App.
                </h4>
                <p className="text-muted">
                The Tokensport App is a sports development platform that allows fans, players, and clubs to invest in amateur and professional athletes, as well as teams and virtual training.
                </p>
                <p className="text-muted mb-0">
                The application aids in the creation of an account to manage funds, publish, mediate, and generate money, as well as sponsorship and investment in a sports-specific marketplace.
                </p>

                <div className="mt-4 pt-2">
                  <a
                    href=""
                    onClick={e => e.preventDefault()}
                    className="btn btn-primary rounded-md"
                  >
                    Read More <i className="uil uil-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

      

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
             {/*} <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4">What Makes Us Different</h4>
                <p className="text-muted para-desc mb-0 mx-auto">
                Fan token platforms are critical for increasing fan interaction and assisting teams in improving sponsor connections and loyalty ecosystems.
                </p>
            </div>*/}
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="row">
           
              <div className="col-lg-4 col-md-6 mt-4 pt-2">
                
              </div>
     
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Section */}

      {/* Footer */}
      <Footer />

      {/* Style switcher  */}
      {/* <StyleSwitcher /> */}
    </>
  )
}

export default AboutUs
