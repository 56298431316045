import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FiArrowRight, FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import '../../common.css'
// image import
import {
  bg1, bg2, bg3,
  client01, client02, client03, client04, client05, client06, client07, client08,
  bg01,
  p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11,p12,p13,p14,p15,
  about,
} from "../../components/imageImport";

import StyleSwitcher from '../../components/StyleSwitcher'

const Partners = () => {
    const navigate = useNavigate()
  return (
    <>
    <Navbar />
    {/* Start Home */}
    <section
      className="bg-half-100 d-table w-100"
      style={{ background: `url(${bg01}) bottom` }}
    >
      <div className="bg-overlay bg-gradient-overlay-2"></div>
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <div className="title-heading text-center">
              <h5 className="heading fw-semibold sub-heading text-white title-dark">
              Potential Partners
              </h5>
              <p className="text-white-50 para-desc mx-auto mb-0">
                NFT marketplace where anything is possible and all are welcome
              </p>
            </div>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}

        
      </div>
      {/*end container*/}
    </section>
    {/*end section*/}
    <div className="position-relative">
      <div className="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    {/* End Home */}

    {/* Start Section */}
    <section className="section team-members">
  
      <div className="container mt-10">
        <div className="row justify-content-center">
       
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p1} style={{ width: "100%" }}/>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p2} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p3} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p4} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p5} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p6} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p7} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p8} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p9} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p10} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p11} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p12} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p13} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p14} style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 col-md-2 col-lg-2">
                        <div class="square-holder h-item">
                        <img alt="" src={p15} style={{ width: "100%" }} />
                        </div>
                    </div>
         
          {/*end col*/}
        </div>
        {/*end row*/}

       
      </div>
      {/*end container*/}
    </section>
    {/*end section*/}
    {/* End Section */}

    {/* Footer */}
    <Footer />

    {/* Style switcher  */}
 
  </>
  )
}

export default Partners