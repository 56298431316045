import React, { useEffect ,useRef} from 'react'
import AppRouter from './router'
import './choices.min.css'
import { NFTProvider } from './context/NFTContext'
import useAlan from './components/Alan'

const App = () => {
  useAlan();
  const AlanBtnContainer=useRef();
  useEffect(() => {
    //document.getElementById('theme-opt').href = './css/style.min.css'
  }, [])

  return (<NFTProvider><AppRouter /><div ref={AlanBtnContainer}/></NFTProvider>)
}

export default App
