import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import { whiteLogo } from '../../components/imageImport'
import StyleSwitcher from '../../components/StyleSwitcher'
import Swal from 'sweetalert2'
const axios = require('axios').default;


const SignUp = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const [name, setName]= useState('');
  const [email, setEmail]= useState('');
  const [password, setPassword]= useState('');

  useEffect(() => {
    setTimeout(() => {
      if (location?.pathname === '/index-two-dark-rtl') {
        document.getElementById('theme-opt').href = './css/style-dark-rtl.min.css'
      } else if (location?.pathname === '/index-two') {
        document.getElementById('theme-opt').href = './css/style.min.css'
      } else if (location?.pathname === '/index-two-rtl') {
        document.getElementById('theme-opt').href = './css/style-rtl.min.css'
      } else {
        document.getElementById('theme-opt').href = './css/style-dark.min.css'
      }
      toggleSwitcher(false)
    }, 100)
  }, [location?.pathname])

  
  const toggleSwitcher = () => {
    var i = document.getElementById('style-switcher')
    if (i) {
      if (i.style.left === '-189px') {
        i.style.left = '0px'
      } else {
        i.style.left = '-189px'
      }
    }
  }

const handleSubmit=(e)=>{ 
  e.preventDefault();

  axios.post('https://nftbe.rbdemo.live/api/v1/auth/register',
  
  {
    "name": name,
    "email": email,
    "password": password,
    "role": "publisher"
}
  )
  .then(function (response) {

    Swal.fire(
      'Success',
      'you have successfully registered',
      'success'
    )
    //localStorage.setItem('token', response.token);
    navigate('/login');
    //token

  })
  .catch(function (error) {
   
    Swal.fire(
      'Error',
      'Please Try Again',
      'error'
    )
  });



}



  return (
    <>
    <div className="round-effect">
        <div className="primary-round opacity-3"></div>
        <div className="gradient-round opacity-3"></div>
      </div>

      <div className="back-to-home">
        <a
          href=""
          onClick={e => e.preventDefault()}
          className="back-button btn btn-pills btn-sm btn-icon btn-primary"
        >
          <FiArrowLeft className="icons" />
        </a>
      </div>

      {/*  Hero Start  */}
      <section className="position-relative">
      
        <div className="bg-overlay bg-linear-gradient-2"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="d-flex flex-column min-vh-100 p-4">
                {/*  Start Logo  */}
                <div className="text-center">
                <a href="/token-sports">
                    <img src={whiteLogo} alt="" />
                  </a>
                </div>
                {/*  End Logo  */}

                {/*  Start Content  */}
                <div className="title-heading text-center my-auto">
                  <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                    <form>
                      <h5 className="mb-4">Register Your Account</h5>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="RegisterName"
                              placeholder="Harry"
                              value={name}
                              onChange={e=>{setName(e.target.value)}}
                            />
                            <label htmlFor="RegisterName">First Name</label>
                          </div>
                        </div>
                        {/* end col */}

                        <div className="col-lg-12">
                          <div className="form-floating mb-2">
                            <input
                              type="email"
                              className="form-control"
                              id="RegisterEmail"
                              placeholder="name@example.com"
                              value={email}
                              onChange={e=>{setEmail(e.target.value)}}
                            />
                            <label htmlFor="RegisterEmail">Email Address</label>
                          </div>
                        </div>
                        {/* end col */}

                        <div className="col-lg-12">
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control"
                              id="RegisterPassword"
                              placeholder="Password"
                              value={password}
                              onChange={e=>{setPassword(e.target.value)}}
                            />
                            <label htmlFor="RegisterPassword">Password</label>
                          </div>
                        </div>
                        {/* end col */}

                        <div className="col-lg-12">
                          <div className="form-check align-items-center d-flex mb-3">
                            <input
                              className="form-check-input mt-0"
                              type="checkbox"
                              value=""
                              id="AcceptT&C"
                            />
                            <label
                              className="form-check-label text-muted ms-2"
                              htmlFor="AcceptT&C"
                            >
                              I Accept{' '}
                              <a
                                href=""
                                onClick={e => e.preventDefault()}
                                className="text-primary"
                              >
                                Terms And Condition
                              </a>
                            </label>
                          </div>
                        </div>
                        {/* end col */}

                        <div className="col-lg-12">
                          <button
                            className="btn btn-primary rounded-md w-100"
                            type="submit"
                            onClick={e =>{handleSubmit(e)}}
                          >
                            Register
                          </button>
                        </div>
                        {/* end col */}

                        <div className="col-12 text-center mt-4">
                          <small>
                            <span className="text-muted me-2">
                              Already have an account ?{' '}
                            </span>{' '}
                            <a
                              href="/login"
                              onClick={e => {
                                e.preventDefault()
                                navigate('/login')
                              }}
                              className="text-dark fw-bold"
                            >
                              Sign in
                            </a>
                          </small>
                        </div>
                        {/* end col */}
                      </div>
                      {/* end row */}
                    </form>
                  </div>
                </div>
                {/*  End Content  */}

                {/*  Start Footer  */}
               {/* <div className="text-center">
                  <small className="mb-0 text-light title-dark">
                  © Copyrights 2022 &nbsp;
                    <a
                      href="https://www.ascension-holding.com/"
                      target="_blank"
                      className="text-reset"
                    >
                      Ascension
                    </a>
                    .
                  </small>
                </div>*/}
                {/*  End Footer  */}
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      {/*  Hero End  */}

      {/* Style switcher  */}
      {/* <StyleSwitcher /> */}
    </>
  )
}

export default SignUp
