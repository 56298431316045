import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import { whiteLogo } from '../../components/imageImport'
import StyleSwitcher from '../../components/StyleSwitcher'
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import axios from 'axios';
const Login = () => {
  const navigate = useNavigate()

  function validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }



const [password,setPassword] =useState('');

const [email, setEmail] = useState('');


  const handleLogin=(e)=>{
    e.preventDefault();
   
    if(validateEmail(email)){


      axios.post('https://nftbe.rbdemo.live/api/v1/auth/login', {
        email: email,
        password: password
      })
      .then(function (response) {

        localStorage.setItem('tokenjson', JSON.stringify(response));

        localStorage.setItem('token', response.data.token);

        Swal.fire(
          'Success',
          'you have successfully logged in',
          'success'
        )

        localStorage.setItem("name",email);
        navigate('/upload-work');
      })
      .catch(function (error) {

        Swal.fire(
          'error',
          'Invalid credentials ',
          'error'
        )
      });



     

    }
    else{

      Swal.fire({
        title: 'Error!',
        text: ' Invalid email address?',
        icon: 'error',
        confirmButtonText: 'OK'
      })

    }

   
   
 
  }

  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      if (location?.pathname === '/index-two-dark-rtl') {
        document.getElementById('theme-opt').href = './css/style-dark-rtl.min.css'
      } else if (location?.pathname === '/index-two') {
        document.getElementById('theme-opt').href = './css/style.min.css'
      } else if (location?.pathname === '/index-two-rtl') {
        document.getElementById('theme-opt').href = './css/style-rtl.min.css'
      } else {
        document.getElementById('theme-opt').href = './css/style-dark.min.css'
      }
      toggleSwitcher(false)
    }, 100)
  }, [location?.pathname])

  
  const toggleSwitcher = () => {
    var i = document.getElementById('style-switcher')
    if (i) {
      if (i.style.left === '-189px') {
        i.style.left = '0px'
      } else {
        i.style.left = '-189px'
      }
    }
  }

  return (
    <>
    <div className="round-effect">
        <div className="primary-round opacity-3"></div>
        <div className="gradient-round opacity-3"></div>
      </div>
      
      <div className="back-to-home">
        <a
          href=""
          onClick={e => e.preventDefault()}
          className="back-button btn btn-pills btn-sm btn-icon btn-primary"
        >
          <FiArrowLeft className="icons" />
        </a>
      </div>

      {/*  Hero Start  */}
      <section className="position-relative">
        
        <div className="bg-overlay bg-linear-gradient-2"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="d-flex flex-column min-vh-100 p-4">
                {/*  Start Logo  */}
                <div className="text-center">
                  <a href="/token-sports">
                    <img src={whiteLogo} alt="" />
                  </a>
                </div>
                {/*  End Logo  */}

                {/*  Start Content  */}
                <div className="title-heading text-center my-auto">
                  <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                    <form>
                      <h5 className="mb-4">Login</h5>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-floating mb-2">
                            <input
                              type="email"
                              className="form-control"
                              id="LoginEmail"
                              placeholder="name@example.com"
                              value={email}
                              onChange={e=>{ setEmail(e.target.value)}}
                            />
                            <label htmlFor="LoginEmail">Email Address:</label>
                          </div>
                        </div>
                        {/* end col */}

                        <div className="col-lg-12">
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control"
                              id="LoginPassword"
                              placeholder="Password"
                              value={password}
                              onChange={e=>{ setPassword(e.target.value)}}
                            />
                            <label htmlFor="LoginPassword">Password:</label>
                          </div>
                        </div>
                        {/* end col */}

                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between">
                            <div className="mb-3">
                              <div className="form-check align-items-center d-flex mb-0">
                                <input
                                  className="form-check-input mt-0"
                                  type="checkbox"
                                  value=""
                                  id="RememberMe"
                                />
                                <label
                                  className="form-check-label text-muted ms-2"
                                  htmlFor="RememberMe"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <small className="text-muted mb-0">
                              <a
                                href="/reset-password"
                                onClick={e => {
                                  e.preventDefault()
                                  navigate('/reset-password')
                                }}
                                className="text-muted fw-semibold"
                              >
                                Forgot password ?
                              </a>
                            </small>
                          </div>
                        </div>
                        {/* end col */}
                        <div className="col-lg-12">
                          <button
                            className="btn btn-primary rounded-md w-100"
                            type="submit"
                            onClick= {(e)=>{ handleLogin(e)} }
                          >
                            Sign in
                          </button>
                        </div>
                        {/* end col */}

                        <div className="col-12 text-center mt-4">
                          <small>
                            <span className="text-muted me-2">
                              Don't have an account ?
                            </span>{' '}
                            <a
                              href="/signup"
                              onClick={e => {
                                e.preventDefault()
                                navigate('/signup')
                              }}
                              className="text-dark fw-bold"
                            >
                              Sign Up
                            </a>
                          </small>
                        </div>
                        {/* end col */}
                      </div>
                      {/* end row */}
                    </form>
                  </div>
                </div>
                {/*  End Content  */}

                {/*  Start Footer  */}
               {/* <div className="text-center">
                  <small className="mb-0 text-light title-dark">
                
                    © Copyrights 2022 &nbsp;
                    <a
                      href="https://www.ascension-holding.com/"
                      target="_blank"
                      className="text-reset"
                    >
                      Ascension
                    </a>
                    .
              
                  </small>
                </div>*/}
                {/*  End Footer  */}
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      {/*  Hero End  */}

      {/* Style switcher  */}
      {/* <StyleSwitcher /> */}
    </>
  )
}

export default Login
